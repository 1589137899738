.personal_content {
  .joinBox {
    width: 100vw;
    height: 0.6rem;
    padding: 16px;
    background: #1e1e1e;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    &-avater {
      width: 0.44rem;
      height: 0.44rem;
      line-height: 0.44rem;
      border-radius: 50%;
      background-color: #343435;
      color: #fff;
      text-align: center;
    }
    &-title {
      max-width: 207px;
      color: #fff;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      font-size: 12px;
      letter-spacing: 0em;
      font-family: HSSRegular;
    }
    &-btn {
      background-color: #fff;
      color: #343435;
      font-size: 14px;
      font-family: HSSBlod;
      font-weight: 500;
      border: none;
      padding: 0.06rem 0.12rem;
      border-radius: 0.08rem;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #2b3c60;
    .top_block {
      width: 1.8rem;
      height: 0.96rem;
      // height: 100%;
      margin: 0 auto;
      background-image: url("../../assets/personal-center/bg.png");
      background-position: top center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
    }
    .bottom_block {
      position: relative;
      background-color: #121212;
      min-height: 5rem;
      height: calc(100% - 0.96rem);
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
      padding-top: 0.16rem;

      .userInfo {
        &-header {
          padding-left: 0.16rem;
          height: 0.7rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &_left {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          // flex: 1 1 auto;
          &_avater {
            width: 0.64rem;
            height: 0.64rem;
            border-radius: 50%;
            margin-right: 0.12rem;
            background-color: #343435;
            color: #fff;
            text-align: center;
            line-height: 0.64rem;
            // img {
            //   width: 100%;
            // }
          }
          &_name {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .name {
              font-size: 18px;
              // font-weight: bold;
              color: #fff;
              font-family: HSSBlod;
            }
            .tips {
              display: flex;
              align-items: center;
              font-size: 12px;
              color: #7c7c80;
              padding-top: 0.08rem;
              font-family: HSSRegular;
              img {
                display: inline-block;
                width: 0.16rem;
                height: 0.16rem;
                margin-left: 0.04rem;
              }
            }
          }
          &_level {
            display: flex;
            flex-direction: column;
            padding-right: 0.16rem;
            .icon {
              width: .65rem;
              padding: 0 .1rem;
              img {
                width: .43rem;
                padding-bottom: 0.04rem;
              }
            }
            .level {
              font-size: 14px;
              // font-weight: bold;
              text-align: center;
              color: #fff;
              font-family: HSSBlod;
            }
          }
        }

        &-bio {
          font-size: 12px;
          color: #545456;
          font-family: HSSRegular;
          padding: 0.2rem 0.16rem 0 0.16rem;
          &.ellipsis {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: calc(100% - 0.3rem);
          }
          &.ellipsis-3 {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          &::before {
            content: "";
            float: right;
            width: 0.4rem;
            height: 100%;
          }
        }
        &-more {
          font-size: 12px;
          color: #545456;
          position: absolute;
          right: 0.16rem;
          top: 0.23rem;
        }

        &-person {
          padding-left: 0.16rem;
          margin: 0.12rem 0;
          display: flex;
          justify-content: flex-start;
          .gender,
          .locaion {
            font-size: 12px;
            font-family: HSSRegular;
            color: #ccccce;
            margin-right: 0.08rem;

            .item {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 0.42rem;
              height: 0.22rem;
              border: 0.01rem solid #343435;
              border-radius: 0.06rem;
              padding: 0.04rem 0.06rem 0.04rem 0.06rem;
              img {
                width: 0.14rem;
                // height: 0.18rem;
              }
            }
          }
        }

        &-interests {
          padding-left: 0.16rem;
          margin: 0.16rem 0 0.2rem 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          .items {
            text-align: left;
            border: 0.5px solid rgba(255, 255, 255, 0);
            // 55 -21
            padding: 0.053rem .1rem;
            font-size: 12px;
            color: #ccccce;
            background: #343435;
            border-radius: 0.08rem;
            margin-bottom: 0.08rem;
            margin-right: 0.08rem;
            font-family: HSSRegular;
          }
        }

        &-list {
          padding-left: 0.16rem;
          display: flex;
          align-items: center;
          .items {
            margin-right: 0.16rem;
            .value {
              font-size: 16px;
              font-weight: bold;
              font-family: HSSBlod;
              color: #ccccce;
              padding-bottom: 0.03rem;
            }
            .title {
              font-size: 12px;
              color: #7c7c80;
              font-family: HSSRegular;
            }
          }
        }
      }

      .follow {
        padding: 0 0.08rem;
        margin-top: 0.16rem;
        button {
          width: 100%;
          height: 0.4rem;
          background-color: #00bfff;
          border-radius: 0.4rem;
          border: none;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          line-height: 1.4;
          font-size: 0.14rem;
          span {
            margin-right: 0.06rem;
            font-size: 0.16rem;
            line-height: 1;
            margin-top: -0.03rem;
          }
        }
      }

      .video_list {
        margin-top: 0.2rem;
        .tabTitle {
          position: sticky;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 9999;
          border-bottom: 1px solid #262626;
          .item {
            flex: 1;
            text-align: center;
            color: #808080;
            font-size: 16px;
            font-family: HSSRegular;
            line-height: 0.2rem;
            position: relative;
            height: 0.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active {
              color: #fff;
              position: relative;
              &::before {
                content: "";
                width: 0.24rem;
                height: 0.03rem;
                background: #fff;
                display: inline-block;
                position: absolute;
                bottom: 0;
                border-radius: 0.1rem;
              }
            }
          }
        }

        .tabContent {
          width: 200vw;
          display: flex;
          display: -webkit-box; /* 老版本语法: Safari 3.1-6,  iOS 6-, Android browser, older WebKit browsers.  */
          display: -moz-box; /* 老版本语法: Firefox 19- (buggy but mostly works) */
          display: -ms-flexbox;
          align-items: flex-start;
          -moz-align-items: flex-start;
          -webkit-align-items: flex-start;
          -ms-align-items: flex-start;
          justify-content: flex-start;
          -webkit-justify-content: flex-start;
          -moz-justify-content: flex-start;
          -ms-justify-content: flex-start;
          overflow: hidden;
          transition: all linear 0.3s;
          padding-bottom: 0.68rem;
          box-sizing: content-box;
          .postsBox,
          .likesBox {
            width: 100vw;
            padding: 0.12rem;
            display: flex;
            flex-wrap: wrap;

            .item {
              width: 33.33%;
              // padding: 0.03rem;
              position: relative;
              // margin: .015rem;
              margin-bottom: 0.03rem;
              img,
              video {
                display: block;
                width: 99%;
                height: 1.6rem;
                object-fit: cover;
                border-radius: 0.04rem;
              }
            }
          }
        }
      }
    }
  }

  .footer-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.84rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #1e1e1e;
    // padding-bottom: .34rem;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 1.67rem;
      height: 0.44rem;
      border: none;
      border-radius: 0.1rem;
      background: #fff;
      color: #343435;
      font-size: 16px;
      font-family: HSSBlod;
      margin-bottom: .2rem;
      img {
        width: 0.32rem;
        margin-left: -0.04rem;
      }
    }
  }

  .loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 999999;
    pointer-events: none;
    background-color: #000;
    transition: all linear 0.3s;
    .img_box {
      width: 2.6rem;
      -webkit-animation: shake 5s linear infinite;
      animation: shake 5s linear infinite;
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      img {
        width: 100%;
      }
    }
    h6 {
      color: #fff;
      font-size: 0.2rem;
    }
  }
  .nodata {
    width: calc(100vw - 0.24rem);
    color: #fff;
    text-align: center;
    font-size: 0.14rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0.8rem;
    img {
      width: 0.64rem;
      height: 0.64rem;
    }
    span {
      padding-top: 0.1rem;
    }
  }
}

@-webkit-keyframes shake {
  //   0% { -webkit-transform: rotate(0deg) translate(0px); }
  //   25% { -webkit-transform: rotate(-10deg) translate(-10px); }
  //   50% { -webkit-transform: rotate(0deg) translate(0px); }
  //   75% { -webkit-transform: rotate(10deg) translate(10px); }
  //   100% { -webkit-transform: rotate(0deg) translate(0px); }
  0% {
    -webkit-transform: translateY(0px);
  }
  25% {
    -webkit-transform: translateY(-0.075rem);
  }
  50% {
    -webkit-transform: translate(0px);
  }
  75% {
    -webkit-transform: translateY(0.1rem);
  }
  100% {
    -webkit-transform: translateY(0px);
  }
}
@keyframes shake {
  // 0% { -webkit-transform: rotate(0deg) translate(0px); }
  // 25% { -webkit-transform: rotate(-10deg) translate(-10px); }
  // 50% { -webkit-transform: rotate(0deg) translate(0px); }
  // 75% { -webkit-transform: rotate(10deg) translate(10px); }
  // 100% { -webkit-transform: rotate(0deg) translate(0px); }
  0% {
    -webkit-transform: translateY(0px);
  }
  25% {
    -webkit-transform: translateY(-0.075rem);
  }
  50% {
    -webkit-transform: translate(0px);
  }
  75% {
    -webkit-transform: translateY(0.1rem);
  }
  100% {
    -webkit-transform: translateY(0px);
  }
}

@media screen and (min-width: 1200px) {
  .personal_content .content .bottom_block .video_list .tabContent .postsBox,
  .personal_content .content .bottom_block .video_list .tabContent .likesBox {
    column-count: 5;
  }
}

@media (min-width: 780px) and (max-width: 1200px) {
  .personal_content .content .bottom_block .video_list .tabContent .postsBox,
  .personal_content .content .bottom_block .video_list .tabContent .likesBox {
    column-count: 4;
  }
}

.chatMain-modal {
  .arco-modal-header {
    display: flex;
    align-items: center;
  }
  .arco-modal-content {
    height: 500px;
    overflow-y: auto;
    position: relative;
    padding: 24px 20px;
    color: var(--color-text-1);
    font-size: 14px;
    display: flex;
  }
  .arco-list-wrapper {
    width: 100%;
    height: 100%;
  }
  .arco-list-content {
    overflow: visible;
  }
  .arco-modal-close-icon {
    position: absolute;
    right: 20px;
    top: 18px;
    font-size: 12px;
    cursor: pointer;
    color: var(--color-text-1);
  }

  .arco-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  .arco-spin-loading .arco-spin-children::after {
    opacity: 0 !important;
  }
}
input[name="button-radio-group"]:focus-visible + .arco-btn {
  box-shadow: 0 0 0 2px rgb(232, 243, 255);
}

.custom-radio-card {
  margin-bottom: 8px;
  padding: 10px 16px;
  border: 1px solid rgb(242, 243, 245);
  border-radius: 4px;
  box-sizing: border-box;
}

.custom-radio-card-mask {
  height: 14px;
  width: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid rgb(229, 230, 235);
  box-sizing: border-box;
}

.custom-radio-card-mask-dot {
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.custom-radio-card-title {
  color: rgb(29, 33, 41);
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

input[name="card-radio-group"]:focus-visible + .custom-radio-card {
  box-shadow: 0 0 0 2px rgb(148, 191, 255);
}

.custom-radio-card:hover,
.custom-radio-card-checked,
.custom-radio-card:hover .custom-radio-card-mask,
.custom-radio-card-checked .custom-radio-card-mask {
  border-color: rgb(22, 93, 255);
}

.custom-radio-card-checked {
  background-color: rgb(232, 243, 255);
}

.custom-radio-card:hover .custom-radio-card-title,
.custom-radio-card-checked .custom-radio-card-title {
  color: rgb(22, 93, 255);
}

.custom-radio-card-checked .custom-radio-card-mask-dot {
  background-color: rgb(22, 93, 255);
}
